<template>
  <v-form @submit.prevent="submitResponse">
    <p>
      A mass spectrum of a compound containing one bromine atom will show two peaks of equal size in
      the molecular ion region. This is because bromine has two naturally occurring isotopes,
      <chemical-latex content="^79Br" /> (78.9183 amu) and
      <chemical-latex content="^81Br" /> (80.9163 amu), which exist in an approximately 1:1 ratio.
      Isotopic abundances vary in different places on Earth, but in a particular sample, the percent
      abundances of <chemical-latex content="^79Br" /> and <chemical-latex content="^81Br" /> are
      equal to <stemble-latex :content="percent79 + '%'" /> and
      <stemble-latex :content="percent81 + '%'" />, respectively. Calculate the average atomic mass
      of bromine in this sample.
    </p>
    <calculation-input
      v-model="inputs.input1"
      prepend-text="Average Atomic Mass:"
      append-text="amu"
    />
  </v-form>
</template>

<script>
import DynamicQuestionMixin from '@/tasks/mixins/dynamic-question';
import CalculationInput from '@/tasks/components/inputs/CalculationInput.vue';
import ChemicalLatex from '@/tasks/components/displayers/ChemicalLatex.vue';
import StembleLatex from '@/tasks/components/StembleLatex.vue';

export default {
  name: 'CalculateAverageAtomicMassIsotopicGrader',
  components: {StembleLatex, ChemicalLatex, CalculationInput},
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        input1: null,
      },
    };
  },
  computed: {
    percent79() {
      let content = this.taskState.getValueBySymbol('79Percent').content;
      content = content.number;
      return content / 100;
    },
    percent81() {
      return 100 - this.percent79;
    },
  },
};
</script>

<style scoped></style>
